//
//
//
//
//
//

import Vue from 'vue';
import { useContent } from '@vsf-enterprise/contentstack';
import { onSSR, useVSFContext } from '@vue-storefront/core';
import { useRoute } from '@nuxtjs/composition-api';
/*
 * This page is prepared for CMS dynamic content rendering.
 */
export default Vue.extend({
  name: 'CMSDynamicPage',
  setup(_props) {
    const { i18n: { locale } } = useVSFContext();
    const route = useRoute();
    const { path } = route.value;
    const { search, content } = useContent(path);
    onSSR(async () => {
      /**
       * The path was equal to `/default/home-page`.
       * That's why it could not retrieve the right
       * contentstack entry. Remove the locale
       * from the path and it will work.
       */
      await search({
        url: path.replace(`/${locale}/`, ''),
        // url: path,
        locale: locale === 'default' ? 'en-us' : locale,
      });
    });
    return {
      content,
    };
  },
});
